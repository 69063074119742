import "./landing.scss";
import { useState } from "react";

// components
import Header from "../../components/header"
import Footer from "../../components/footer"
import Licenses from "../../components/licenses"

export default function Landing() {
  const [type, setType] = useState('Node.js')
  const [showLicenses, setShowlicenses] = useState(false)

  return (
    <div id="landing-page">
      <div id="main">
        <Header/>

        <div id="about">
          <div className="row">
            <div className="row">
              <div id="info">
                <div id="info-header">The ANALYTIC TOOL THAT WILL MOST BENEFIT YOU</div>

                <div className="info-mini-header">Understand how people are using your product by PAGE CHARTS</div>
                <div className="info-mini-header">See datas on PAGE CHARTS by<br/>SECONDS, MINUTES, HOURS, DAYS, WEEKS, MONTHS and YEARS</div>
              </div>
              <div className="column" style={{ width: '50%' }}>
                <div id="screenshot">
                  <div id="screenshot-header">
                    The chart of a Dashboard page
                  </div>
                  <div id="screenshot-holder">
                    <img alt="screenshot" src="/screenshot.jpg"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="main-header">Track every single action your users take</div>

        <div id="example-usage">
          <div id="example-usage-header">THE ONLY API YOU NEED (for Python)</div>

          <div id="example-install">
            <div className="example-install-header">Install</div>
            <div className="row"><div id="example-code-install">pip install biginsight</div></div>
            <div className="example-install-header">The Usage</div>
          </div>

          <div id="example-display">            
            <div className="line-numbers">
              {Array.from({ length: 10 }, (_, k) => k + 1).map(index => (
                <>{index}<br/></>
              ))}
            </div>
            <div className="code">
              from BigInsight import biginsight<br/><br/>

              biginsight.key = "biin-**********" <strong># get from your dashboard</strong><br/><br/>

              biginsight.track(&#123;<br/>
              {'  '}"userInfo": "user@gmail.com", <strong># email or id</strong><br/>
              {'  '}"page": "Landing", <strong># page name</strong><br/>
              {'  '}"action": "Register", <strong># action on page</strong><br/>
              {'  '}"isError": True, <strong># log error if action didn't succeed (Optional, default to False)</strong><br/>
              &#125;)
            </div>
          </div>
        </div>

        <div id="install">
          <div className="install-header">
            Select your preferred language
          </div>

          <div className="row">
            <div id="types">
              <div className={"type" + (type === 'Node.js' ? '-selected' : '')} onClick={() => setType('Node.js')}>Node.js</div>
              <div className={"type" + (type === 'Ruby' ? '-selected' : '')} onClick={() => setType('Ruby')}>Ruby</div>
              <div className={"type" + (type === 'cURL' ? '-selected' : '')} onClick={() => setType('cURL')}>cURL</div>
              <div className={"type" + (type === 'Python' ? '-selected' : '')} onClick={() => setType('Python')}>Python</div>
            </div>
          </div>

          {(type === "Node.js" || type === "Ruby" || type === "Python") ?
            <>
              <div className="usage-header">Install</div>

              <div className="row">
                <div id="code-installer">
                  {type === "Node.js" && 'npm install biginsight --save'}
                  {type === "Ruby" && (
                    <>
                      gem install biginsight<br/>
                    </>
                  )}
                  {type === "Python" && (
                    <>
                      pip install biginsight
                    </>
                  )}
                </div>
              </div>
            </>
            :
            <div className="usage-header">No Installation Required</div>
          }

          <div id="code-usage-header">The Usage</div>

          <div className="row">
            <div id="code-usage-notes">
              <div id="code-usage-notes-header">NOTES</div>

              key: <strong>get from your dashboard</strong><br/>
              userInfo: <strong># user id or email</strong><br/>
            </div>
          </div>

          <div id="code-usage-container">
            {type === "Node.js" && (
              <>
                <div class="line-numbers">
                  {Array.from({ length: 10 }, (_, k) => k + 1).map(index => (
                    <>{index}<br/></>
                  ))}
                </div>
                <div className="code">
                  const BigInsight = require("biginsight")<br/><br/>

                  const bigInsight = new BigInsight('biin-*********'); <strong>{'//'} get from your dashboard</strong><br/><br/>

                  const resp = await bigInsight.track(&#123;<br/>
                  {'   '}"userInfo": "user@gmail.com", <strong># user id or email</strong><br/>
                  {'   '}"page": "Landing", <strong># page</strong><br/>
                  {'   '}"action": "Register", <strong># action name</strong><br/>
                  {'   '}"isError": False <strong>Default to False</strong><br/>
                  &#125;)
                </div>
              </>
            )}
            {type === "Ruby" && (
              <>
                <div class="line-numbers">
                  {Array.from({ length: 11 }, (_, k) => k + 1).map(index => (
                    <>{index}<br/></>
                  ))}
                </div>
                <div className="code">
                  require 'biginsight'<br/><br/>

                  # Initialize the BigInsight library<br/>
                  BigInsight.key = 'biin-*********'<br/><br/>

                  BigInsight.track(&#123;<br/>
                  {'   '}"userInfo": "user@gmail.com", <strong># user id or email</strong><br/>
                  {'   '}"page": "Landing", <br/>
                  {'   '}"action": "Visit", <br/>
                  {'   '}"isError": false<br/>
                  &#125;)
                </div>
              </>
            )}
            {type === "cURL" && (
              <>
                <div className="line-numbers">
                  {Array.from({ length: 9 }, (_, k) => k + 1).map(index => (
                    <>{index}<br/></>
                  ))}
                </div>
                <div className="code">
                  curl -X POST https://api.biginsight.ca/api/track \<br/>
                  -H "Content-Type: application/json" \<br/>
                  -d '&#123;<br/>
                  {'   '}"key": "biin-************", <br/>
                  {'   '}"userInfo": "user@gmail.com", <br/>
                  {'   '}"page": "Landing",<br/>
                  {'   '}"action": "Register",<br/>
                  {'   '}"isError": false<br/>
                  &#125;'
                </div>
              </>
            )}
            {type === "Python" && (
              <>
                <div class="line-numbers">
                  {Array.from({ length: 11 }, (_, k) => k + 1).map(index => (
                    <>{index}<br/></>
                  ))}
                </div>
                <div className="code">
                  from biginsight import biginsight<br/><br/>

                  biginsight.key = "biin-*********"<br/><br/>

                  # the usage<br/>

                  biginsight.track(&#123;<br/>
                  {'   '}"userInfo": "user@gmail.com", <strong># user id or email</strong><br/>
                  {'   '}"page": "Landing", <strong># page</strong><br/>
                  {'   '}"action": "Register", <strong># action name</strong><br/>
                  {'   '}"isError": False <strong>Default to False</strong><br/>
                  &#125;)
                </div>
              </>
            )}
          </div>
        </div>

        <div id="footer">
          <Footer seeLicenses={() => setShowlicenses(true)}/>
        </div>
      </div>

      {showLicenses && (
        <div id="licenses-container" onClick={() => setShowlicenses(false)}>
          <Licenses/>
        </div>
      )}
    </div>
  )
}