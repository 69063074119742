import "./footer.scss";

export default function Footer(props) {
  return (
    <div id="footer-comp">
      <div className="row">
        <div id="options">
          <div className="option" onClick={() => window.location = "/terms"}>Terms</div>
          <div className="option" onClick={() => window.location = "/policy"}>Policy</div>
        </div>
      </div>
      <div id="footer-header">@ 2024 BigInsight by Geottuse, Inc. | All Rights Reserved</div>
      <div id="license-header" onClick={() => props.seeLicenses()}>Licenses</div>
    </div>
  )
}
