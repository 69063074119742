import "./policy.scss";
import { useState } from "react";

// components
import Header from "../../components/header"
import Footer from "../../components/footer"
import Licenses from "../../components/licenses"

export default function Policy() {
  const [showLicenses, setShowlicenses] = useState(false)

  return (
    <div id="policy-page">
      <div id="main">
        <Header/>
        
        <div id="body">
          <div id="policy-header">Privacy Policy</div>

          <div className="policy-header">Last Updated: Mon, Sept 9, 2024</div>
          <div className="policy-info">
            At BigInsight ("we," "our," or "us"), we are committed to protecting your privacy. 
            This Privacy Policy explains how we collect, use, and safeguard your information 
            when you use BigInsight (the "BigInsight"). By accessing or using the Library, 
            you agree to the collection and use of information in accordance with this policy.
          </div>

          <div className="policy-header">1. Information We Collect</div>
          <div className="policy-info">
            When you sign up on our website to obtain your Key for the Library, we collect 
            the following information:

            <ul>
              <li><strong>Personal Information:</strong> Your email address will only be collected during registration.</li>
              <li><strong>Usage Data:</strong> Information about your users' movements tracked through the API setup by you within your application or website.</li>
            </ul>
          </div>

          <div className="policy-header">2. How We Use Your Information</div>
          <div className="policy-info">
            We use the information we collect to:

            <ul id="dot">
              <li>Communicate with you about your progress and limited usage</li>
            </ul>
          </div>

          <div className="policy-header">3. Data Protection and Security</div>
          <div className="policy-info">
            We implement reasonable measures to protect your information from unauthorized access, 
            disclosure, alteration, and destruction. However, no method of transmission over the 
            internet or electronic storage is completely secure, and we cannot guarantee absolute 
            security.
          </div>

          <div className="policy-header">4. Data Sharing</div>
          <div className="policy-info">
            We do not sell, trade, or otherwise transfer your personal information to third parties, except:

            <ul>
              <li><strong>For Legal Reasons:</strong> To comply with legal obligations, protect our rights, or enforce our policies.</li>
            </ul>
          </div>

          <div className="policy-header">5. Data Retention</div>
          <div className="policy-info">
            We retain your information for as long as necessary to fulfill the purposes 
            outlined in this Privacy Policy. If you wish to delete your account or request removal of your data, 
            please contact us at support@geottuse.com.
          </div>

          <div className="policy-header">6. Your Rights</div>
          <div className="policy-info">
            Depending on your location, you may have rights regarding your personal data, such as the right to 
            access, correct, or delete your information. To exercise these rights, please contact us at 
            support@geottuse.com.
          </div>

          <div className="policy-header">7. Changes to This Policy</div>
          <div className="policy-info">
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting 
            the new policy on our website. Your continued use of the Library after such changes constitutes 
            your acceptance of the new policy.
          </div>

          <div className="policy-header">8. Contact Us</div>
          <div className="policy-info">
            If you have any questions or concerns about this Privacy Policy, please contact us at: support@geottuse.com
          </div>
        </div>

        <div id="footer">
          <Footer seeLicenses={() => setShowlicenses(true)}/>
        </div>
      </div>

      {showLicenses && (
        <div id="licenses-container" onClick={() => setShowlicenses(false)}>
          <Licenses/>
        </div>
      )}
    </div>
  )
}
