const routeUrl = process.env.REACT_APP_API_URL + "/api/user"

export const register = data => {
  return fetch(routeUrl + "/register", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const login = data => {
  return fetch(routeUrl + "/login", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const getVerified = data => {
  return fetch(routeUrl + "/get_verified", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}
