import './main.scss';
import { useEffect, useState } from "react";
import { login, register } from "../../apis/user";
import { addProject, getProjects, getProjectInfo, deleteProject } from "../../apis/project";
import { addPlatform, getPlatformInfo, deletePlatform, createCheckout } from "../../apis/platform";
import { addPage, getPages, deletePage, viewBy, addPageAction, deletePageAction, searchPageNames, getPageData } from "../../apis/page";
import { FaCcStripe, FaAngleDown } from "react-icons/fa";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoIosClose } from "react-icons/io";
import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// components
import Header from "../../components/header"
import Footer from "../../components/footer"
import Licenses from "../../components/licenses"

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function Main() {
  const [token, setToken] = useState('')
  const [platforms, setPlatforms] = useState([])
  const [projectInfo, setProjectinfo] = useState({ show: false, id: null, name: '',  loading: false })
  const [platformInfo, setPlatforminfo] = useState({
    show: false, id: null, name: '',
    testToken: '', copiedTest: false,
    liveToken: '', copiedLive: false, numRequests: 0,
    loading: false, pages: []
  })
  const [projects, setProjects] = useState([])

  // hidden box
  const [addNewproject, setAddnewproject] = useState({ show: false, name: '', loading: false })
  const [addNewplatform, setAddnewplatform] = useState({ show: false, name: '' })
  const [addNewpage, setAddnewpage] = useState({
    show: false,
    name: ''
  })
  const [addNewpageaction, setAddnewpageaction] = useState({
    show: false,
    name: '',
    pageId: -1, pageName: '', pageIndex: -1
  })
  const [deleteNewproject, setDeletenewproject] = useState({ show: false, id: null })
  const [deleteNewplatform, setDeletenewplatform] = useState({ show: false, id: null })
  const [deleteNewpage, setDeletenewpage] = useState({ show: false, id: null, name: '', index: -1 })
  const [deleteNewpageaction, setDeletenewpageaction] = useState({
    show: false,
    pageId: -1, pageName: '', pageIndex: -1,
    actionId: '', actionName: '', actionIndex: -1
  })
  const [showLicenses, setShowlicenses] = useState(false)

  const addTheProject = () => {
    const { show, name } = addNewproject

    if (!show) {
      setAddnewproject({ ...addNewproject, show: true, name: '' })
    } else {
      setAddnewproject({ ...addNewproject, loading: true })

      const data = { token, name }

      addProject(data)
        .then((res) => {
          if (res.ok) {
            return res.json()
          }

          throw res
        })
        .then((res) => {
          if (res) {
            setAddnewproject({ ...addNewproject, show: false, name: '', loading: false })
            getTheProjects()
          }
        })
        .catch((err) => {
          if (err.status === 400) {
            err.json().then(({ status }) => {
              switch (status) {

              }
            })
          }
        })
    }
  }
  const deleteTheProject = (projectId, index) => {
    if (!deleteNewproject.show) {
      setDeletenewproject({
        ...deleteNewproject,
        show: true, id: projectId
      })
    } else {
      setDeletenewproject({
        ...deleteNewproject, 
        loading: true 
      })

      const { id } = deleteNewproject
      const data = { token, projectId: id }

      deleteProject(data)
        .then((res) => {
          if (res.ok) {
            return res.json()
          }

          throw res
        })
        .then((res) => {
          if (res) {
            setProjects(res.projects)
            setDeletenewproject({
              ...deleteNewproject,
              show: false, id: null,
              loading: false
            })
          }
        })
        .catch((err) => {
          if (err.status === 400) {
            err.json().then(({ status }) => {
              switch (status) {
                
              }
            })
          }
        })
    }
  }
  const getTheProjects = () => {
    const data = { token }

    getProjects(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          setProjects(res.projects)
        }
      })
      .catch((err) => {
        if (err.status === 400) {

        }
      })
  }
  const getTheProjectInfo = id => {
    const data = { token, id }

    setProjectinfo({ ...projectInfo, loading: true })

    getProjectInfo(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          const { name } = res

          setProjectinfo({ ...projectInfo, id, name: res.name })
          setPlatforminfo({ ...platformInfo, show: false, id: null, name: '' })
          setPlatforms(res.platforms)
        }
      })
      .catch((err) => {
        if (err.status === 400) {

        }
      })
  }
  const getThePlatformInfo = id => {
    const data = { token, platformId: id }

    getPlatformInfo(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }
        
        throw res
      })
      .then((res) => {
        if (res) {
          const { name, testToken, liveToken, pages, numRequests } = res

          setPlatforminfo({
            ...platformInfo,
            show: true,
            testToken, liveToken, numRequests, 
            id, name, loading: false,
            pages
          })
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          err.json().then(({ status }) => {

          })
        }
      })
  }
  const addThePlatform = () => {
    const { show, name } = addNewplatform

    if (!show) {
      setAddnewplatform({ ...addNewplatform, show: true, name: '' })
    } else {
      setAddnewplatform({ ...addNewplatform, loading: true })

      const data = { token, projectId: projectInfo.id, name }

      addPlatform(data)
        .then((res) => {
          if (res.ok) {
            return res.json()
          }

          throw res
        })
        .then((res) => {
          if (res) {
            setAddnewplatform({ ...addNewplatform, show: false, name: '', loading: false })
            getTheProjectInfo(projectInfo.id)
          }
        })
    }
  }
  const deleteThePlatform = (platformId, index) => {
    if (!deleteNewplatform.show) {
      setDeletenewplatform({
        ...deleteNewplatform,
        show: true, id: platformId
      })
    } else {
      setDeletenewplatform({
        ...deleteNewplatform,
        loading: true
      })

      const { id } = deleteNewplatform
      const data = { token, platformId: id }

      deletePlatform(data)
        .then((res) => {
          if (res.ok) {
            return res.json()
          }

          throw res
        })
        .then((res) => {
          if (res) {
            setPlatforms(res.platforms)
            setDeletenewplatform({
              ...deleteNewplatform, show: false,
              loading: false
            })
          }
        })
        .catch((err) => {
          if (err.status === 400) {
            err.json().then(({ status }) => {
              switch (status) {
                
              }
            })
          }
        })
    }
  }
  const createTheCheckout = platformId => {
    const data = { token, platformId }

    createCheckout(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          window.location = res.sessionUrl
        }
      })
      .catch((err) => {

      })
  }
  const addThePage = () => {
    const { show, name } = addNewpage

    if (!show) {
      setAddnewpage({ ...addNewpage, show: true, name: '' })
    } else {
      setAddnewpage({ ...addNewpage, loading: true })

      const data = { token, platformId: platformInfo.id, name }

      addPage(data)
        .then((res) => {
          if (res.ok) {
            return res.json()
          }

          throw res
        })
        .then((res) => {
          if (res) {
            setAddnewpage({ ...addNewpage, show: false, name: '', loading: false })

            getThePlatformInfo(platformInfo.id)
          }
        })
        .catch((err) => {
          if (err.status === 400) {

          }
        })
    }
  }
  const searchThePageNames = pageName => {
    const data = { token, platformId: platformInfo.id, pageName }

    searchPageNames(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          setPlatforminfo({ ...platformInfo, pages: res.pages })
        }
      })
      .catch((err) => {
        if (err.status === 400) {

        }
      })
  }
  const getThePageData = (id, index) => {
    const data = { token, pageId: id }
    const { pages } = platformInfo
    const pageData = pages[index]

    if (pageData["actions"]) {
      delete pages[index]["actions"]
      delete pages[index]["viewBy"]
      delete pages[index]["label"]
      delete pages[index]["data"]

      setPlatforminfo({ ...platformInfo, pages })
    } else {
      getPageData(data)
        .then((res) => {
          if (res.ok) {
            return res.json()
          }

          throw res
        })
        .then((res) => {
          if (res) {
            pages.forEach(function (page) {
              delete page["actions"]
              delete page["viewBy"]
              delete page["label"]
              delete page["data"]
            })

            pages[index]["actions"] = res.actions
            pages[index]["viewBy"] = res.viewBy
            pages[index]["label"] = res.label
            pages[index]["data"] = res.data

            setPlatforminfo({ ...platformInfo, pages })
          }
        })
        .catch((err) => {
          if (err.status === 400) {

          }
        })
    }
  }
  const displayPageTimeHeader = timeStr => {
    return (
      timeStr === 'minute' && 'Minutely'
      ||
      timeStr === 'hour' && 'Hourly'
      ||
      timeStr === 'day' && 'Daily'
      ||
      timeStr === 'week' && 'Weekly'
      ||
      timeStr === 'month' && 'Monthly'
      ||
      timeStr === 'year' && 'Yearly'
    )
  }
  const deleteThePage = index => {
    if (!deleteNewpage.show) {
      const page = platformInfo.pages[index]

      setDeletenewpage({
        ...deleteNewpage,
        show: true, id: page.id, name: page.name, index
      })
    } else {
      setDeletenewpage({
        ...deleteNewpage,
        loading: true
      })

      const { id, index } = deleteNewpage
      const data = { token, pageId: id }

      deletePage(data)
        .then((res) => {
          if (res.ok) {
            return res.json()
          }

          throw res
        })
        .then((res) => {
          if (res) {
            const { pages } = platformInfo

            pages.splice(index, 1)

            setPlatforminfo({ ...platformInfo, pages })
            setDeletenewpage({ ...deleteNewpage, show: false, loading: false })
          }
        })
        .catch((err) => {
          if (err.status === 400) {

          }
        })
    }
  }
  const viewTheBy = (pageId, type, action, index) => {
    const data = { token, pageId, type, action }
    const { pages } = platformInfo

    viewBy(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          pages[index].label = res.label
          pages[index].data = res.data
          pages[index].viewBy = res.viewBy

          setPlatforminfo({ ...platformInfo, pages })
        }
      })
      .catch((err) => {
        if (err.status === 400) {

        }
      })
  }
  const deleteThePageAction = (page, pageIndex, action, actionIndex) => {
    if (!deleteNewpageaction.show) {
      setDeletenewpageaction({ 
        ...deleteNewpageaction, 
        show: true, 
        pageId: page.id, pageName: page.name, pageIndex, 
        actionId: action.id, actionName: action.header, actionIndex })
    } else {
      setDeletenewpageaction({ ...deleteNewpageaction, loading: true })

      const { pageIndex, actionId, actionIndex } = deleteNewpageaction
      const data = { token, actionId }

      deletePageAction(data)
        .then((res) => {
          if (res.ok) {
            return res.json()
          }

          throw res
        })
        .then((res) => {
          if (res) {
            const pages = [...platformInfo.pages]

            pages[pageIndex].actions.splice(actionIndex, 1)

            setPlatforminfo({ ...platformInfo, pages })
            setDeletenewpageaction({ 
              ...deleteNewpageaction, show: false, pageId: '', pageName: '', 
              pageIndex: -1, actionId: '', actionName: '', loading: false
            })
          }
        })
    }
  }
  const addThePageAction = (page, index) => {
    const { show, name, pageId, pageIndex } = addNewpageaction

    if (!show) {
      setAddnewpageaction({ ...addNewpageaction, show: true, name: '', pageId: page.id, pageName: page.name, pageIndex: index })
    } else {
      setAddnewpageaction({ ...addNewpageaction, loading: true })

      const data = { token, name, pageId }

      addPageAction(data)
        .then((res) => {
          if (res.ok) {
            return res.json()
          }

          throw res
        })
        .then((res) => {
          if (res) {
            const pages = [...platformInfo.pages]

            pages[pageIndex].actions = res.actions

            setAddnewpageaction({ ...addNewpageaction, show: false, name: '', pageId: -1, pageName: '', pageIndex: -1, loading: false })
            setPlatforminfo({ ...platformInfo, pages })
          }
        })
        .catch((err) => {
          if (err.status === 400) {
            
          }
        })
    }
  }

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setToken(localStorage.getItem("token"))
    }
  }, [])

  useEffect(() => {
    if (token) {
      getTheProjects()
    }
  }, [token])

  return (
    <div id="main-page">
      <Header onDashboard={true}/>

      <div id="main">
        <div id="sidebar">
          <div id="projects-section">
            <div id="projects-header">PROJECTS</div>

            <div id="projects">
              {projects.map((project, index) => (
                <div className="project" onClick={() => getTheProjectInfo(project.id)} key={project.key}>
                  <div className="column" style={{ width: '80%' }}><div className="project-name">{project.name}</div></div>
                  <div className="project-delete">
                    <i className="fa-solid fa-xmark fa-1x" onClick={(e) => {
                      deleteTheProject(project.id, index)

                      e.stopPropagation()
                    }}></i>
                  </div>
                </div>
              ))}
            </div>

            <div id="project-add">
              <i className="fa-solid fa-plus fa-2x" onClick={() => addTheProject()}></i>
            </div>
          </div>

          {projectInfo.name && (
            <div id="platforms-section">
              <div id="platforms-header">
                {projectInfo.name}
                <br/>
                <div style={{ fontSize: 13 }}>
                  (Add platform)<br/>web, ios, android etc
                </div>
              </div>

              <div id="platforms">
                {platforms.map((platform, index) => (
                  <div className="platform" onClick={() => getThePlatformInfo(platform.id)} key={platform.key}>
                    <div className="column" style={{ width: '80%' }}><div className="platform-name">{platform.name}</div></div>
                    <div className="platform-delete">
                      <i className="fa-solid fa-xmark fa-1x" onClick={(e) => {
                        deleteThePlatform(platform.id, index)

                        e.stopPropagation()
                      }}></i>
                    </div>
                  </div>
                ))}
              </div>

              <div id="platform-add">
                <i className="fa-solid fa-plus fa-2x" onClick={() => addThePlatform()}></i>
              </div>
            </div>
          )}
        </div>

        <div id="dashboard">
          {platformInfo.show ? 
            <>
              <div id="project-info">
                <div id="project-info-title">{platformInfo.name}</div>

                <div className="row">
                  <div className="row" style={{ padding: '20px 0' }}>
                    <div className="project-info-header" onClick={() => {
                      navigator.clipboard.writeText(platformInfo.testToken)

                      setAddnewproject({ ...addNewproject, copiedTest: true })

                      setTimeout(function () {
                        setAddnewproject({ ...addNewproject, copiedTest: false })
                      }, 1000)
                    }}>
                      Test Token: <strong>biin-***************</strong>
                      <br/>
                      <div className="row">
                        <div className="token-copied-header">{!addNewproject.copiedTest ? "Click to copy" : "Copied"}</div>
                      </div>
                      <strong>(Requests & Data won't be counted)</strong>
                    </div>
                    <div className="project-info-header" onClick={() => {
                      navigator.clipboard.writeText(platformInfo.liveToken)

                      setAddnewproject({ ...addNewproject, copiedLive: true })

                      setTimeout(function () {
                        setAddnewproject({ ...addNewproject, copiedLive: false })
                      }, 1000)
                    }}>
                      Live Token: <strong>biin-***************</strong>
                      <br/>
                      <div className="row">
                        <div className="token-copied-header">{!addNewproject.copiedLive ? "Click to copy" : "Copied"}</div>
                      </div>
                    </div>
                  </div>
                </div>

                {platformInfo.numRequests < 100 ? 
                  <>
                    <div id="project-info-numrequests">({100 - platformInfo.numRequests} request(s) left)</div>
                  </>
                  :
                  <div style={{ margin: '30px 0' }}>
                    <div id="project-info-nomorerequests">This platform ran out of requests</div>
                  </div>
                }

                <div id="pages-add">
                  <div className="row">
                    <strong id="create-page-button" onClick={() => addThePage()}>Add a page</strong> 
                    <div id="create-page-button-header">to start tracking</div>
                  </div>
                </div>
              </div>

              <div id="search-page">
                <input
                  maxLength={50}
                  type="text"
                  placeholder="Search pages by name to view data"
                  onChange={e => searchThePageNames(e.target.value)}
                />
              </div>

              <div id="pages-list">
                {platformInfo.pages.map((page, index) => (
                  <div className="page" key={page.key}>
                    <div className="page-row">
                      <div className="page-header">Page: <strong style={{ fontWeight: 100 }}>{page.name}</strong></div>
                      <div className="column">
                        <div className="page-showdata" onClick={() => getThePageData(page.id, index)}>
                          <div className="page-showdata-header">{page.label ? 'Hide' : 'Show'} Graph</div>
                          <div className="column">
                            <div className="page-showdata-icon">
                              {page.label ? 
                                <IoIosClose style={{ height: '100%', width: '100%' }}/>
                                :
                                <FaAngleDown style={{ height: '100%', width: '100%' }}/>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="column">
                        <div className="page-delete" onClick={() => deleteThePage(index)}>
                          <div className="page-delete-header">Delete Page</div>
                          <div className="column">
                            <div className="page-delete-icon">
                              <i className="fa-solid fa-xmark fa-1x"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {page.label && (
                      <>
                        <div className="row">
                          <div className="page-viewby">
                            <div className="column"><div className="page-viewby-header">View by (time) : </div></div>

                            <div className="viewby-options">
                              <div className={"viewby-option" + (page.viewBy.time === 'second' ? '-selected' : '')} onClick={() => viewTheBy(page.id, 'second', page.viewBy.action, index)}>Seconds</div>
                              <div className={"viewby-option" + (page.viewBy.time === 'minute' ? '-selected' : '')} onClick={() => viewTheBy(page.id, 'minute', page.viewBy.action, index)}>Minutes</div>
                              <div className={"viewby-option" + (page.viewBy.time === 'hour' ? '-selected' : '')} onClick={() => viewTheBy(page.id, 'hour', page.viewBy.action, index)}>Hours</div>
                              <div className={"viewby-option" + (page.viewBy.time === 'day' ? '-selected' : '')} onClick={() => viewTheBy(page.id, 'day', page.viewBy.action, index)}>Days</div>
                              <div className={"viewby-option" + (page.viewBy.time === 'week' ? '-selected' : '')} onClick={() => viewTheBy(page.id, 'week', page.viewBy.action, index)}>Weeks</div>
                              <div className={"viewby-option" + (page.viewBy.time === 'month' ? '-selected' : '')} onClick={() => viewTheBy(page.id, 'month', page.viewBy.action, index)}>Months</div>
                              <div className={"viewby-option" + (page.viewBy.time === 'year' ? '-selected' : '')} onClick={() => viewTheBy(page.id, 'year', page.viewBy.action, index)}>Years</div>
                            </div>
                          </div>
                        </div>
                      
                        <div className="page-graph-row">
                          <div className="page-graph">
                            {page.label ? 
                              <Bar 
                                data={{
                                  labels: page.label,
                                  datasets: [{
                                    label: 'Page: ' + page.name,
                                    data: page.data,
                                    backgroundColor: 'black'
                                  }]
                                }}
                                options={{
                                  responsive: true,
                                  plugins: {
                                    title: {
                                      color: 'black',
                                      display: true,
                                      text: 'Number of Actions (' + page.viewBy.action + ') (' + displayPageTimeHeader(page.viewBy.time) + ')',  // Title for the chart
                                      font: {
                                        size: 20,
                                      },
                                      padding: {
                                        bottom: 20,
                                      },
                                    },
                                    legend: {
                                      position: 'top',
                                    },
                                    tooltip: {
                                      callbacks: {
                                        label: function (context) {
                                          return `${context.dataset.label}: ${context.raw}`;
                                        },
                                      },
                                    },
                                  },
                                  scales: {
                                    x: {
                                      beginAtZero: true,
                                      title: {
                                        display: true,
                                        text: page.viewBy.time + 's',
                                      },
                                    },
                                    y: {
                                      beginAtZero: true,
                                      title: {
                                        display: true,
                                        text: 'Number of Actions (' + displayPageTimeHeader(page.viewBy.time) + ')'
                                      },
                                    },
                                  },
                                }}
                              />
                              :
                              <div className="page-no-result">No data yet</div>
                            }
                          </div>
                          <div className="viewtypes">
                            <div className="viewtypes-header">View by (action)</div>

                            <div className="viewtypes-list">
                              {page.actions ? 
                                page.actions.map((action, actionIndex) => (
                                  <div 
                                    className={"viewtype" + ((page.viewBy && action.header === page.viewBy.action) ? '-selected' : '')} 
                                    key={action.key} 
                                    onClick={() => viewTheBy(page.id, page.viewBy.time, action.header, index)}
                                  >
                                    <div className="column" style={{ width: '90%' }}><div className="viewtype-header">{action.header}</div></div>
                                    <div className="viewtype-delete"><i className="fa-solid fa-xmark fa-2x" onClick={() => deleteThePageAction(page, index, action, actionIndex)}></i></div>
                                  </div>
                                ))
                                :
                                <div className="viewtypes-no-result">No actions added yet</div>
                              }
                            </div>

                            <div className="row">
                              <div className="page-action-add" onClick={() => addThePageAction(page, index)}>
                                <div className="page-action-add-header">Add action</div>
                                <div className="columm">
                                  <i className="fa-solid fa-plus fa-1x"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </>
            :
            <div id="dashboard-header">Select a project & platform to view its page datas</div>
          }
        </div>
      </div>
      <div id="footer"><Footer seeLicenses={() => setShowlicenses(true)}/></div>
      
      {(
        // add
        addNewproject.show || 
        addNewplatform.show || 
        addNewpage.show || 
        addNewpageaction.show || 

        // delete
        deleteNewproject.show || 
        deleteNewplatform.show || 
        deleteNewpage.show || 
        deleteNewpageaction.show || 
        showLicenses
      ) && (
        <div id="hidden-box" onClick={(e) => {
          setAddnewproject({ ...addNewproject, show: false, name: '' })
          setAddnewplatform({ ...addNewplatform, show: false, name: '' })
          setAddnewpage({ ...addNewpage, show: false, name: '' })
          setAddnewpageaction({ ...addNewpageaction, show: false, name: '' })
          setDeletenewproject({ ...addNewproject, show: false, name: '' })
          setDeletenewplatform({ ...deleteNewplatform, show: false, name: '' })
          setDeletenewpage({ ...deleteNewpage, show: false, pageId: null, index: -1 })
          setDeletenewpageaction({ ...deleteNewpageaction, show: false })
          setShowlicenses(false)

          e.stopPropagation()
        }}>
          {addNewproject.show && (
            <div id="addproject-box" onClick={(e) => e.stopPropagation()}>
              <div id="addproject-header">Enter project name</div>

              <div id="addproject-input">
                <input
                  maxLength="50"
                  placeholder="Enter project name"
                  disabled={addNewproject.loading}
                  onChange={(e) => setAddnewproject({ ...addNewproject, name: e.target.value })}
                />
              </div>

              {addNewproject.loading && (
                <div className="row">
                  <div id="loading">
                    <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                  </div>
                </div>
              )}

              <div id="addproject-button" onClick={!addNewproject.loading ? () => addTheProject() : null}>Add</div>
            </div>
          )}
          {addNewplatform.show && (
            <div id="addplatform-box" onClick={(e) => e.stopPropagation()}>
              <div id="addplatform-header">Enter platform name</div>

              <div id="addplatform-input">
                <input
                  maxLength="50"
                  placeholder="Enter platform name"
                  disabled={addNewplatform.loading}
                  onChange={(e) => setAddnewplatform({ ...addNewplatform, name: e.target.value })}
                />
              </div>

              {addNewplatform.loading && (
                <div className="row">
                  <div id="loading">
                    <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                  </div>
                </div>
              )}

              <div id="addplatform-button" onClick={!addNewplatform.loading ? () => addThePlatform() : null}>Add</div>
            </div>
          )}
          {addNewpage.show && (
            <div id="addpage-box" onClick={(e) => e.stopPropagation()}>
              <div id="addpage-header">Enter page name</div>

              <div id="addpage-input">
                <input
                  maxLength="50"
                  placeholder="Enter page name"
                  disabled={addNewpage.loading}
                  onChange={(e) => setAddnewpage({ ...addNewpage, name: e.target.value })}
                />
              </div>

              {addNewpage.loading && (
                <div className="row">
                  <div id="loading">
                    <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                  </div>
                </div>
              )}

              <div id="addpage-button" onClick={!addNewpage.loading ? () => addThePage() : null}>Add</div>
            </div>
          )}
          {addNewpageaction.show && (
            <div id="addpageaction-box" onClick={(e) => e.stopPropagation()}>
              <div id="addpageaction-header">Enter action name for {addNewpageaction.pageName}</div>

              <div id="addpageaction-input">
                <input
                  maxLength="50"
                  placeholder="Enter action name"
                  disabled={addNewpageaction.loading}
                  onChange={(e) => setAddnewpageaction({ ...addNewpageaction, name: e.target.value })}
                />
              </div>

              {addNewpageaction.loading && (
                <div className="row">
                  <div id="loading">
                    <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                  </div>
                </div>
              )}

              <div id="addpageaction-button" onClick={!addNewpageaction.loading ? () => addThePageAction() : null}>Add</div>
            </div>
          )}
          {deleteNewproject.show && (
            <div id="deleteproject-box">
              <div id="deleteproject-header">Remove the project ? ({deleteNewproject.name})</div>
            
              <div className="row">
                <div id="deleteproject-actions">
                  <div className="deleteproject-action" onClick={!deleteNewproject.loading ? () => setDeletenewproject({ ...deleteNewproject, show: false, name: '' }) : null}>Cancel</div>
                  <div className="deleteproject-action" onClick={!deleteNewproject.loading ? () => deleteTheProject() : null}>Yes</div>
                </div>
              </div>

              {deleteNewproject.show && (
                <div className="row">
                  <div id="loading">
                    <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                  </div>
                </div>
              )}
            </div>
          )}
          {deleteNewplatform.show && (
            <div id="deleteplatform-box">
              <div id="deleteplatform-header">Remove the platform ? {deleteNewplatform.name}</div>

              <div className="row">
                <div id="deleteplatform-actions">
                  <div className="deleteplatform-action" onClick={!deleteNewplatform.loading ? () => setDeletenewplatform({ ...deleteNewplatform, show: false, name: '' }) : null}>Cancel</div>
                  <div className="deleteplatform-action" onClick={!deleteNewplatform.loading ? () => deleteThePlatform() : null}>Yes</div>
                </div>
              </div>

              {deleteNewplatform.loading && (
                <div className="row">
                  <div id="loading">
                    <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                  </div>
                </div>
              )}
            </div>
          )}
          {deleteNewpage.show && (
            <div id="deletepage-box" onClick={e => e.stopPropagation()}>
              <div id="deletepage-header">Remove the page ? {deleteNewpage.name}</div>

              <div className="row">
                <div id="deletepage-actions">
                  <div className="deletepage-action" onClick={!deleteNewpage.loading ? () => setDeletenewpage({ ...deleteNewpage, show: false, pageId: null, index: -1 }) : null}>Cancel</div>
                  <div className="deletepage-action" onClick={!deleteNewpage.loading ? () => deleteThePage() : null}>Yes</div>
                </div>
              </div>

              {deleteNewpage.loading && (
                <div className="row">
                  <div id="loading">
                    <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                  </div>
                </div>
              )}
            </div>
          )}
          {deleteNewpageaction.show && (
            <div id="deletepageaction-box">
              <div id="deletepageaction-header">Remove the page action ? {deleteNewpageaction.actionName}</div>

              <div className="row">
                <div id="deletepageaction-actions">
                  <div className="deletepageaction-action" onClick={!deleteNewpageaction.loading ? () => setDeletenewpageaction({ ...deleteNewpageaction, show: false, pageId: '', pageName: '', pageIndex: -1, actionId: '', actionName: '' }) : null}>Cancel</div>
                  <div className="deletepageaction-action" onClick={!deleteNewpageaction.loading ? () => deleteThePageAction() : null}>Yes</div>
                </div>
              </div>

              {deleteNewpageaction.loading && (
                <div className="row">
                  <div id="loading">
                    <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                  </div>
                </div>
              )}
            </div>
          )}
          {showLicenses && (
            <div id="licenses-container" onClick={() => setShowlicenses(false)}>
              <Licenses/>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
