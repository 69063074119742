const routeUrl = process.env.REACT_APP_API_URL + "/api/project"

export const addProject = data => {
  return fetch(routeUrl + "/add_project", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const getProjects = data => {
  return fetch(routeUrl + "/get_projects", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const getProjectInfo = data => {
  return fetch(routeUrl + "/get_project_info", {
    method: "POST",
    headers: { "Content-Type": "application/json"},
    body: JSON.stringify(data)
  })
}

export const deleteProject = data => {
  return fetch(routeUrl + "/delete_project", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}
