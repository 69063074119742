const routeUrl = process.env.REACT_APP_API_URL + "/api/page"

export const addPage = data => {
  return fetch(routeUrl + "/add_page", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const getPages = data => {
  return fetch(routeUrl + "/get_pages", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const deletePage = data => {
  return fetch(routeUrl + "/delete_page", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const viewBy = data => {
  return fetch(routeUrl + "/view_by", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const addPageAction = data => {
  return fetch(routeUrl + "/add_page_action", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const deletePageAction = data => {
  return fetch(routeUrl + "/delete_page_action", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const searchPageNames = data => {
  return fetch(routeUrl + "/search_pages", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const getPageData = data => {
  return fetch(routeUrl + "/get_page_data", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}
