import "./header.scss";

export default function Header({ onDashboard, checkout }) {
  const token = localStorage.getItem("token")

  return (
    <div id="header-comp" 
      style={
        onDashboard ? 
          { backgroundColor: 'black', padding: 0 }
          :
          { backgroundColor: 'rgba(0, 0, 0, 0.8)', padding: '0 2.5%' }
      }
    >
      <div id="header-row">
        <div id="title" onClick={() => window.location = "/"}>BigInsight | <strong>LEARN FROM YOUR USERS</strong></div>

        {!checkout && (
          <div id="header-navs">
            <div className="header-nav" onClick={() => {
              window.location = "/"
            }}>About</div>

            <div className="header-nav" onClick={() => {
              if (token) {
                localStorage.clear()
              }

              window.location = token ? "/" : "/auth"
            }}>
              {token ? 
                'Log-Out'
                :
                'Log-In/Sign-Up'
              }
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
