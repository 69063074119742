import "./checkout_status.scss";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// components
import Header from "../../components/header"

let counter = null

export default function CheckoutStatus() {
  const queryParams = new URLSearchParams(useLocation().search);
  const status = queryParams.get('status');
  const [count, setCount] = useState(3)

  const startCountdown = () => {
    counter = setInterval(function () {
      setCount(prev => {
        if (prev <= 1) {
          clearInterval(counter)

          window.location = "/"

          return 1
        }
        
        return prev - 1
      })
    }, 1000)
  }

  useEffect(() => {
    startCountdown()
  }, [])
  
  return (
    <div id="checkout-status-page">
      <div id="main">
        <Header checkout={true}/>

        <div id="body">
          <div id="body-headers">
            <div className="body-header">
              {(status === "success" || status === "failed") ? 
                status === "success" ? 
                  <>
                    Your requests has been reset to 0.<br/>You have another 100 requests"
                  </> 
                  : 
                  "Payment cancelled"
                :
                "Request not found"
              }
            </div>
            <div className="body-header">Return to main page in {count}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
