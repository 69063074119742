import "./App.scss";
import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Landing from "./pages/landing";
import Auth from "./pages/auth"
import Verified from "./pages/verified"
import Main from "./pages/main";
// import Priceplan from "./pages/priceplan";
import CheckoutStatus from "./pages/checkout_status"

import Terms from "./pages/terms"
import Policy from "./pages/policy"

export default function App() {
  const [token, setToken] = useState('')

  useEffect(() => {
    setToken(
      localStorage.getItem("token") ? 
        localStorage.getItem("token") 
        : 
        ''
    )
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={token ? <Main/> : <Landing/>}/>
        <Route path="/landing" element={<Landing/>}/>
        <Route path="/auth" element={<Auth/>}/>
        <Route path="/verified/:token" element={<Verified/>}/>
        <Route path="/main" element={<Main/>}/>
        {/* <Route path="/priceplan" element={<Priceplan/>}/> */}
        <Route path="/checkout_status" element={<CheckoutStatus/>}/>

        <Route path="/terms" element={<Terms/>}/>
        <Route path="/policy" element={<Policy/>}/>
      </Routes>
    </BrowserRouter>
  )
}
