import "./auth.scss";
import React, { useState } from 'react';
import { FaCheck } from "react-icons/fa";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { login, register } from "../../apis/user";

// components
import Header from "../../components/header"
import Footer from "../../components/footer"
import Licenses from "../../components/licenses"

export default function Auth() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [newAccount, setNewaccount] = useState(false)
  const [confirmPassword, setConfirmpassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [showLicenses, setShowlicenses] = useState(false)
  const [emailSent, setEmailsent] = useState(false)
  const [agreed, setAgreed] = useState(false)
  const [errorMsg, setErrormsg] = useState('')

  const theAuth = () => {
    setLoading(true)

    const data = { email, password }

    login(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          localStorage.setItem("token", res.token)

          window.location = "/"
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          err.json().then(({ status }) => {
            switch (status) {
              case "noAccount":
                setLoading(false)
                setNewaccount(true)

                break;
              default:
            }
          })
        }
      })
  }
  const theRegister = () => {
    if (password.length >= 6) {
      if (confirmPassword) {
        if (password === confirmPassword) {
          if (agreed) {
            setLoading(true)

            const data = { email, password }

            register(data)
              .then((res) => {
                if (res.ok) {
                  return res.json()
                }

                throw res
              })
              .then((res) => {
                if (res) {
                  setLoading(false)
                  setEmailsent(true)
                }
              })
              .catch((err) => {
                if (err.status === 400) {

                }
              })
          } else {
            setErrormsg("You must agree to the terms")
          }
        } else {
          setErrormsg("Password mismatch")
        }
      } else {
        setErrormsg("Please confirm your password")
      }
    } else {
      setErrormsg("Password needs to be 6 characters or over")
    }
  }

  return (
    <div id="auth-page">
      <Header/>

      <div id="main">
        {emailSent ? 
          <>
            <div id="body-header">Please check your e-mail to verify your account</div>

            <div className="row"><div id="back" onClick={() => setEmailsent(false)}>Back</div></div>
          </>
          :
          <div className="flex items-center justify-center bg-gray-100" style={{ height: '100%', width: '100%' }}>
            <div className="w-full max-w-md p-8 bg-white shadow-md rounded-lg" style={{ opacity: loading ? 0.5 : 1 }}>
              <h2 className="text-2xl font-bold mb-6 text-center">Sign In/Up</h2>

              <div className="mb-4">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  disabled={loading}
                  onChange={(e) => setEmail(e.target.value)}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                />
              </div>

              <div className="mb-4">
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                <input
                  type="password"
                  name="password"
                  value={password}
                  disabled={loading}
                  onChange={(e) => setPassword(e.target.value)}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                />
              </div>

              {newAccount && (
                <div className="mb-4">
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                  <input
                    type="password"
                    name="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmpassword(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    required
                  />
                </div>
              )}

              <div className="row">
                {newAccount && (
                  <button
                    onClick={() => {
                      setNewaccount(false)
                      setErrormsg("")
                    }}
                    className="w-full py-2 px-4 bg-indigo-600 text-white font-semibold rounded-lg shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    style={{ width: '46%' }}
                  >Back</button>
                )}

                <button
                  className="w-full py-2 px-4 bg-indigo-600 text-white font-semibold rounded-lg shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  style={newAccount ? { width: '46%' } : null}
                  disabled={loading}
                  onClick={(e) => {
                    e.stopPropagation()

                    if (newAccount) {
                      theRegister()
                    } else {
                      theAuth()
                    }
                  }}
                >
                  {newAccount ? 
                    'Register'
                    :
                    'Login/Register'
                  }
                </button>
              </div>

              {loading && (
                <div className="row">
                  <div id="loading">
                    <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                  </div>
                </div>
              )}

              <div id="errormsg">{errorMsg}</div>

              <div className="row">
                <div id="agreement">
                  <div className="column">
                    <div id="agreement-check" onClick={() => {
                      if (!loading) {
                        setAgreed(!agreed)
                        setErrormsg("")
                      }
                    }}>
                      {agreed && <FaCheck style={{ color: 'blue', height: '70%', width: '70%' }}/>}
                    </div>
                  </div>
                  <div id="agreement-check-header">You agreed to the <a target="_blank" href="/terms">Terms</a></div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
      
      <Footer seeLicenses={() => setShowlicenses(true)}/>

      {showLicenses && (
        <div id="hidden-box" onClick={() => {
          setShowlicenses(false)
        }}>
          {showLicenses && (
            <div id="licenses-container" onClick={() => setShowlicenses(false)}>
              <Licenses/>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
