const routeUrl = process.env.REACT_APP_API_URL + "/api/platform"

export const addPlatform = data => {
  return fetch(routeUrl + "/add_platform", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const getPlatformInfo = data => {
  return fetch(routeUrl + "/get_platform_info", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const deletePlatform = data => {
  return fetch(routeUrl + "/delete_platform", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const createCheckout = data => {
  return fetch(routeUrl + "/create_checkout", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}
