import "./verified.scss";
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { getVerified } from "../../apis/user"

export default function Verified() {
  const { token } = useParams()

  useEffect(() => {
    const initialize = () => {
      getVerified({ token })
        .then((res) => {
          if (res.ok) {
            return res.json()
          }
        })
        .then((res) => {
          if (res) {
            localStorage.setItem("token", token)

            window.location = "/"
          }
        })
    }

    initialize()
  }, [])

  return (
    <div id="verified-page">
      <div id="main">
        <div id="header">
          <div id="header-row">
            <div id="title" onClick={() => window.location = "/"}>BigInsight | <strong>LEARN FROM YOUR USERS</strong></div>
          </div>
        </div>
        <div id="body">
          <div id="body-headers">
            <div className="body-header">Welcome to BigInsight</div>
          </div>
        </div>
      </div>
    </div>
  )
}
